<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-card>
        <v-card-title>
            Rachunki
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table :headers="headers" :items="invoices" :options.sync="options"
            :loading="loading" class="elevation-1">            
            <template v-slot:item.actions="{ item }">
                <v-btn class="primary" @click="downloadSettlement(item.rentIds)">
                    <v-icon small class="pt-1">mdi-download</v-icon>
                </v-btn>
            </template>
        </v-data-table>        
        <v-footer class="justify-center">
            <h3>Minimalna kwota do wypłaty: 231 netto</h3>
            <h3>Co to jest mikrorachunek podatkowy</h3>
            <p>Jest to indywidualny rachunek podatkowy, który służy do wpłat PIT, CIT i VAT*. Od 1 stycznia 2020 r. podatnicy i
            pracodawcy-płatnicy zapłacą PIT, CIT i VAT* do urzędu skarbowego za jego pomocą.</p>
            
            <p>Mikrorachunek podatkowy służy tylko do wpłat podatku.
            Zwroty nadpłat i podatków będą realizowane na dotychczasowych zasadach, czyli np. na twój ROR.
            Jak sprawdzić numer swojego mikrorachunku podatkowego
            Mikrorachunek podatkowy sprawdzisz korzystając z generatora lub otrzymasz go w dowolnym urzędzie skarbowym.</p>
            <a href="https://www.podatki.gov.pl/generator-mikrorachunku-podatkowego/" target="_blank"><v-btn class="success">Generator Mikrorachunku</v-btn></a>
        
            
        </v-footer>
    </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

export default {
    name: "InvoicesView",
    data: () => ({
        search: '',
        options: {},
        totalRecords: 0,
        loading: false,
        headers: [
            { text: 'Data Rachunku', value: 'dateG' },
            {
                text: 'Kwota',
                align: 'start',
                value: 'totalRent',
                sortable: false,
            },
            {
                text: 'Podatek',
                align: 'start',
                value: 'tax',
                sortable: false,
            },            
            { text: '', value: 'actions', sortable: false, },
        ],       
        invoices: [],        
    }),
    async created() {
         await this.loadInvoices()
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async downloadSettlement(ids){
            await this.toggleLoading(true);
            const file = await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + "/settlements/download-invoice",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data:{
                    ids
                },
                responseType: "blob"
            });   
            let blob = new Blob([file.data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)

            window.open(url)                     
            await this.toggleLoading(false);
        },
        async loadInvoices() {
            this.loading = true
            try {
                // let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const invoices = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-my-invoices`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                invoices.data.data.forEach((item) => {
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
                    item.tax = (parseFloat(item.totalRent)*0.085).toFixed(2)
                })
                this.invoices = invoices.data.data
                // this.itemsPerPage = invoices.data.data.meta.itemsPerPage
                // this.page = invoices.data.data.meta.currentPage
                // this.totalRecords = invoices.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
    },
}
</script>
