<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>BTP Driver App</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form                         ref="form"
                        v-model="valid"
                        lazy-validation>
                            <v-text-field :rules="emailRules" prepend-icon="mdi-account" name="login" v-model="username" label="E-mail"
                                type="text"></v-text-field>
                            <v-text-field :rules="passwordRules" id="password" v-model="password" prepend-icon="mdi-lock" name="password"
                                label="Hasło" type="password"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="getForm()">Zarejestruj Się</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :disabled="!valid" @click="login()">Zaloguj</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
const axios = require("axios");
import Swal from "sweetalert2";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LeeTtwiAAAAAPYyKHvL5dtytGDSrc3e0gNR8qjp" });
export default {
    name: "LoginView",
    data: () => ({
        valid:true,
        password: "",
        username: "",
        emailRules: [
            v => !!v || 'Podaj Poprawny Adres E-mail',
            v => /.+@.+\..+/.test(v) || 'Podaj Poprawny Adres E-mail',
        ],
        passwordRules: [
            v => !!v || 'Hasło Jest Wymagane',            
        ],                 
    }),
    props: {
        source: String,
    },
    methods: {
        async getForm(){
            window.location.href='/form'
        },
        async login() {
            try {     
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('login')                             
                    if (!this.username.trim().length) {
                        throw ({ response: { statusText: 'Podaj E-mail' } })
                    }
                    if (!this.password.trim().length) {
                        throw ({ response: { statusText: 'Podaj Hasło' } })
                    }                   
                    let user = await axios({
                        method: "post",
                        url: process.env.VUE_APP_ROOT_API + "/auth/driver/login",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                        data: {
                            email: this.username.toLocaleLowerCase(),
                            password: this.password,
                            token:token
                        },
                    });
                    localStorage.setItem("user", true);
                    localStorage.setItem("token", user.data.access_token);
                    localStorage.setItem("userid", user.data.id);
                    window.location.href = "/";                                    
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
                localStorage.setItem("user", false);
                localStorage.setItem("token", null);                
                localStorage.setItem("userid", null);                
            }
        },
    },
};
</script>