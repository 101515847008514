<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-card>
                <v-card-title>
                    Faktury Kosztowe
                    <v-spacer></v-spacer>
                    <v-btn class="primary" @click="showDialog('invoiceCost')">Dodaj</v-btn>
                </v-card-title>
                <h3>Faktury kosztowe są rozliczane w momencie osiągnięcia minimalnej kwoty przelewu z aplikacji</h3>
                <h3>
    Przelewy z tytułu zwrotu kosztów wykonywane są w przedostatnim tygodniu miesiąca. Prosimy o dodawanie faktur do 15 dnia każdego miesiąca.
    Do 15 dnia każdego miesiąca można dodawać faktury z bieżącego miesiąca oraz z poprzedniego miesiaca.                     
                </h3>
                <v-data-table :headers="costinvoiceHeaders" :items="costinvoices" :options.sync="costinvoiceOptions"
                    :server-items-length="totalRecordsInvoiceCost" :loading="invoiceLoadingCost" class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="showInvoiceCost(item.id)">
                            <v-icon small class="pt-1">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
        <v-dialog :retain-focus="false" v-model="dialogInvoiceCost" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Dodawanie Faktury Kosztowej</span>
                </v-card-title>
                <v-card-text>
                    <v-from>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Kwota Brutto na Fakturze (Liczba musi zawierać kropkę, nie przecinek!)" v-model="invoiceCostPrice"
                                    placeholder="Kwota Brutto na Fakturze" :rules="[(v) => !!v || 'Uzupełnij Pole']">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Opis" v-model="invoiceCostDescription" placeholder="Opis"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input accept="application/pdf" v-model="invoiceCostFile" type="file" refs="file"
                                    label="Załącznik"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-from>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogInvoiceCost = false">
                        Anuluj
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="createCostInvoice()">
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>                
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  name: "CostsView",
  data: () => ({
      invoiceWeekStr: '',
      totalRecordsInvoiceCost: 0,
      costinvoices: [],
      costinvoiceOptions: {},
      costinvoiceHeaders: [
          {
              text: 'Data Dodania',
              align: 'start',
              value: 'created_at',
          },
          {
              text: 'Opis',
              align: 'start',
              value: 'description',
          },
          {
              text: 'Suma na fakturze',
              align: 'start',
              value: 'totalPrice',
          },
          {
              text: 'Kwota Zwrotu',
              align: 'start',
              value: 'returnPrice',
          },          
          {
              text: 'Status',
              align: 'start',
              value: 'settlementStatus',
          },
          { text: "", value: "actions", sortable: false },
      ],
      invoiceCostPrice: 0,
      invoiceCostDescription: '',
      invoiceCostFile: '',
      invoiceLoadingCost: false,
      dialogInvoiceCost: false,
  }),
  watch:{
      costinvoiceOptions: {
          async handler() {
              await this.getInvoiceCostHistory()
          },
          deep: true,
      },    
  },
  methods:{   
      async showDialog() {
          this.dialogInvoiceCost = true
      }, 
      async createCostInvoice() {
          this.invoiceLoadingCost = true
          try {
              const formData = new FormData()
              try {
                  formData.append("file", this.invoiceCostFile, this.invoiceCostFile.name);
              } catch (e) {
                  console.log('missing file')
              }
              if (!this.invoiceCostPrice.trim().length) {
                  throw ({ response: { statusText: 'Brak Ceny!' } })
              }
              if (!this.invoiceCostDescription.trim().length) {
                  throw ({ response: { statusText: 'Brak Opisu Faktury!' } })
              }
              formData.append('driver', this.currentDriverId)              
              formData.append('totalPrice', this.invoiceCostPrice)
              formData.append('description', this.invoiceCostDescription)              
              await axios({
                  method: "post",
                  url: process.env.VUE_APP_ROOT_API + "/invoices/upload-document-for-verification",
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  data: formData,
              });
              this.invoiceCostFile = null
              this.invoiceCostPrice = 0              
              this.invoiceCostDescription = ''              
              this.dialogInvoiceCost = false
              await this.getInvoiceCostHistory()
          } catch (e) {
              console.log(e)
              Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
          }
          this.invoiceLoadingCost = false
      },         
      async showInvoiceCost(id) {
          try {
              const file = await axios({
                  method: "get",
                  url: process.env.VUE_APP_ROOT_API + `/invoices/get-document/${id}`,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  responseType: "blob"
              });
              let blob = new Blob([file.data], { type: 'application/pdf' }),
                  url = window.URL.createObjectURL(blob)

              window.open(url)
          } catch (e) {
              console.log(e)
              Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
          }
      },           
    async getInvoiceCostHistory() {
        this.invoiceLoadingCost = true
        try {
            let { sortBy, sortDesc, page, itemsPerPage } = this.costinvoiceOptions
            const invoices = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/invoices/get-my-documents?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            invoices.data.data.items.forEach((item) => {
                item.totalPrice = (item.totalPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });
                item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
                item.settlementStatus = item.settlementsStatus == 'uploaded' ?'Oczekuje na Weryfikację':'Zweryfikowany'
            })
            this.costinvoices = invoices.data.data.items
            this.itemsPerPage = invoices.data.data.meta.itemsPerPage
            this.page = invoices.data.data.meta.currentPage
            this.totalRecordsInvoiceCost = invoices.data.data.meta.totalItems
        } catch (e) {
            console.log(e)
            Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
        }
        this.invoiceLoadingCost = false
    }        
  }
}
</script>
