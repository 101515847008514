<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Rejestracja BTP</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form     
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                            <v-text-field prepend-icon="mdi-account" :rules="nameRules" name="firstName" v-model="firstName" label="Imię" type="text" required></v-text-field>
                            <v-text-field prepend-icon="mdi-account" :rules="nameRules" name="lastName" v-model="lastName" label="Nazwisko" type="text" required></v-text-field>                            
                            <v-text-field prepend-icon="mdi-email"  :rules="emailRules" name="email" v-model="email" label="E-mail" required
                                type="mail"></v-text-field>
                            <v-text-field id="phone" :rules="phoneRules" v-model="phone" prepend-icon="mdi-phone" name="phone" label="Numer Telefonu (Z Kierunkowym)" type="phone" required>
                            </v-text-field>
                            <v-text-field id="password" v-model="password" :rules="passwordRules" prepend-icon="mdi-lock" name="password" label="Hasło" type="password" required>
                            </v-text-field>
                            <v-text-field id="passwordRepeat" v-model="passwordRepeat" :rules="passwordRules" prepend-icon="mdi-lock" name="passworRepeat" label="Hasło" required
                                type="password">
                            </v-text-field>
                            <v-select  :rules="[v => !!v || 'Wybierz Opcję']" v-model="formEnumValue" :items="formEnumValues" item-text="text" item-value="id"
                                label="Jak nas znalazłeś?">
                            </v-select>
                            <v-checkbox :rules="[v => !!v || 'Musisz Zaakceptować Regulamin!']" v-model="accepted">
                                <template v-slot:label>
                                    <div>
                                        Akceptuje zasady
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a target="_blank" href="https://btp.btpartner.pl/uploads/contract/Regulamin.pdf" @click.stop v-on="on">
                                                    Regulaminu
                                                </a>
                                            </template>            
                                        </v-tooltip>        
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="getLogin()">Powrót</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                         :disabled="!valid"
                        @click="createDriver()">Wyślij Formularz</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LeeTtwiAAAAAPYyKHvL5dtytGDSrc3e0gNR8qjp" });
const axios = require("axios");
import Swal from "sweetalert2";
export default {
    name: "FormView",
    data: () => ({
        valid: true,
        firstName: '',
        lastName: '',
        password: "",
        passwordRepeat: '',        
        email: "",
        phone: "",
        accepted: true,  
        formEnumValue: null,
        formEnumValues: [{
            id: 'imdriver',
            text: 'Jestem kierowcą i zakładam konto',            
        },
        {
            id: 'recomendation',
            text: 'Z Polecenia'
        },
        {
            id: 'other_partner',
            text: 'Od innego partnera'
        },
            {
                id: 'job_lookup',
                text: 'Szukam Pracy'
            },
        {
            id: 'deligo',
            text: 'Deligo'
        },
        {
            id: 'deligo_silesia',
            text: 'Deligo Śląsk'
        }                            
        ],            
        nameRules: [
            v => !!v || 'Pole nie może być puste',
            v => (v && v.trim().length >= 1) || 'Pole nie może być puste',
        ],              
        emailRules: [
            v => !!v || 'Podaj Poprawny Adres E-mail',
            v => /.+@.+\..+/.test(v) || 'Podaj Poprawny Adres E-mail',
        ],   
        passwordRules: [
            v => !!v || 'Hasło Jest Wymagane',
            v => (v && v.trim().length >= 8) || 'Hasło nie może być krótsze niż 8 znaków',
        ],  
        phoneRules:[
            value => !!value || 'Podaj Numer Telefonu!',
            value => {
                // eslint-disable-next-line no-useless-escape
                const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                return pattern.test(value) || 'Niepoprawny Format Numeru Telefonu'
            },            
        ],                  
    }),  
    methods:{
        async getLogin() {
            window.location.href = '/login'
        },        
        async createDriver(){                     
            try{
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('login')    
                if (!(/.+@.+\..+/).test(this.email)) {
                    throw ({ response: { statusText: 'Podaj E-mail' } })
                }
                // eslint-disable-next-line no-useless-escape
                if (!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/).test(this.phone)) {
                    throw ({ response: { statusText: 'Podaj Numer Telefonu' } })
                }
                if (this.password!== this.passwordRepeat) {
                    throw ({ response: { statusText: 'Hasła nie są identyczne' } })
                }
                if (!this.firstName.trim().length) {
                    throw ({ response: { statusText: 'Podaj Imię' } })
                } 
                if (!this.lastName.trim().length) {
                    throw ({ response: { statusText: 'Podaj Nazwisko' } })
                }                                                
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/create-driver-form",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                    data: {
                        firstName:this.firstName,
                        lastName:this.lastName,
                        password:this.password,                        
                        email: this.email,                        
                        phone: this.phone,
                        formEnumValue: this.formEnumValue,
                        token: token
                    },
                }) 
                Swal.fire("Wysłano", 'Przesłano Formularz, skontaktujemy się z tobą wktórce', "success");
                this.firstName=""
                this.lastName=""
                this.password=""
                this.passwordRepeat='', 
                this.email=""
                this.phone="" 
                this.formEnumValue = null
                this.$refs.form.resetValidation()             
            }catch(e){
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }                       
        }
    }  
}
</script>