<template>
<v-card>
    <v-card-title>
        <span class="text-h5">Moje Konto</span>
    </v-card-title>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col cols="6">
                    <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                        :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                        placeholder="Nazwisko"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                        placeholder="Telefon"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nowe Hasło" required type="password"
                        v-model="password" placeholder="Nowe Hasło"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email"
                        v-model="email" placeholder="E-mail"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Adres" v-model="street" :rules="[((v) => !!v || 'Uzupełnij Pole'),(v => v.length <= 35 || 'Max 35 Znaków')]" required
                        placeholder="Adres"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Adres 2" v-model="streetExtraInfo" :rules="[((v) => !!v || 'Uzupełnij Pole'), (v => v.length <= 35 || 'Max 35 Znaków')]"
                        required placeholder="Adres 2">
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miasto" v-model="city" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                        placeholder="Miasto">
                    </v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="2">
                    <v-checkbox label="Konto za Granicą?" v-model="abroadBankAccount"></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox label="Wypłaty Gotówką" v-model="cashPayments"></v-checkbox>
                </v-col>
                <v-col cols="8">
                    <v-text-field label="Numer Konta" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="accountNo">
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Marka Auta" v-model="carMake">
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Tablica Rejestracyjna Auta" v-model="carPlate">
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-select label="Częstotliwość Wypłat*" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                        v-model="paymentSchedule" :items="paymentsSchedules" item-text="text" item-value="id">
                    </v-select>
                </v-col>
                <p>*Możliwa Zmiana tylko w ciągu pierwszego tygodnia rozliczeniowego w danym miesiącu</p>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions class="float-right">        
        <v-btn color="blue darken-1 " text @click="updateDriver()">
            Aktualizuj
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

export default {
    name: "ConfigView",
    data: () => ({
        carPlate:'',
        carMake: '',
        password: '',
        street: '',        
        streetExtraInfo: '',
        city: '',
        licenceNumber: '',
        abroadBankAccount: false,
        cashPayments: false,
        accountNo: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        paymentSchedule: null,  
        paymentsSchedules: [{
            id: 'week',
            text: 'Tygodniowy'
        },
        {
            id: 'two_week',
            text: '2 Tygodnie'
        }, {
            id: 'month',
            text: 'Miesiąc'
        }],       
    }),
    async mounted(){
        await this.getMyAccount()
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async updateDriver(){
            try {                   
                await this.validateDriverForm()                         
                await axios({
                    method: 'patch',
                    url: process.env.VUE_APP_ROOT_API + "/drivers/update-account",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        password: this.password || null,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        phone: this.phone,
                        street: this.street,
                        streetExtraInfo: this.streetExtraInfo,
                        city: this.city,
                        licenceNumber: this.licenceNumber,
                        abroadBankAccount: this.abroadBankAccount,
                        cashPayments: this.cashPayments,
                        accountNo: this.accountNo,
                        paymentSchedule: this.paymentSchedule, 
                        carMake: this.carMake, 
                        carPlate: this.carPlate,                        
                    },
                });                
                this.firstName = ''
                this.lastName = ''
                this.email = ''
                this.phone = ''
                this.street = ''
                this.streetExtraInfo = ''
                this.city = ''
                this.licenceNumber = ''
                this.abroadBankAccount = false
                this.cashPayments = false
                this.accountNo = '';
                this.password = '';
                this.carMake = '';
                this.carPlate = '';                  
                this.paymentSchedule = null                                
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
            await this.getMyAccount()            
        },
        async validateDriverForm() {
            if (!this.firstName.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Imię'
                    }
                })
            }                                        
            if (!this.lastName.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Nazwisko'
                    }
                })
            }
            if (!this.email.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij E-mail'
                    }
                })
            }
            if (!this.phone.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Telefon'
                    }
                })
            }
            if (!this.street.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Adres'
                    }
                })
            }
            if (!this.streetExtraInfo.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Adres 2'
                    }
                })
            }
            if (!this.city.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Miasto'
                    }
                })
            }
            if(!this.cashPayments){
                if (!this.accountNo.trim().length) {
                    throw ({
                        response: {
                            statusText: 'Uzupełnij Numer Konta'
                        }
                    })
                }
            }
            if (!this.paymentSchedule.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Częstotliwość Rozliczeń'
                    }
                })
            }            
        },        
        async getMyAccount() {
            await this.toggleLoading(true);            
            try {
                let driver = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/my-account",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.firstName = driver.data.data.firstName,
                this.lastName = driver.data.data.lastName,
                this.email = driver.data.data.email,
                this.phone = driver.data.data.phone,                    
                this.street = driver.data.data.street,
                this.streetExtraInfo = driver.data.data.streetExtraInfo,
                this.city = driver.data.data.city,
                this.licenceNumber = driver.data.data.licenceNumber,
                this.abroadBankAccount = driver.data.data.abroadBankAccount,
                this.cashPayments = driver.data.data.cashPayments,
                this.accountNo = driver.data.data.accountNo,
                this.paymentSchedule = driver.data.data.paymentSchedule 
                this.carMake = driver.data.data.carMake
                this.carPlate = driver.data.data.carPlate       
            }catch (e) {
                console.log(e);
                this.dialogCreate = false;
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }            
            await this.toggleLoading(false);  
        },
    },
}
</script>
