<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-card>        
                <v-card-title>
                Dokumenty
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="showDialog('contracts')">Dodaj</v-btn>
                </v-card-title>
                <v-data-table :headers="dealHeaders" :items="deals" :options.sync="dealOptions"
                    :server-items-length="totalRecordsDeal" :loading="contractLoading" class="elevation-1">
                <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="showDeal(item.id)">
                        <v-icon small class="pt-1">mdi-eye</v-icon>
                    </v-btn>
                </template>
            </v-data-table>                    
        <v-dialog :retain-focus="false" v-model="dialogContracts" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Dodawanie Dokumentu</span>
                </v-card-title>
                <v-card-text>
                    <v-from>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Opis" v-model="descriptionContract" required placeholder="Opis"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select label="Rodzaj Dokumentu" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                    v-model="contractType" :items="contractTypes" item-text="text" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input accept="application/pdf" v-model="contractFile" type="file" refs="file"
                                    label="Dokument"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-from>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogContracts = false">
                        Anuluj
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="createContract()">
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
    name: "DocumentsView",
    data: () => ({
        dealHeaders: [
            {
                text: 'Data Dodania',
                align: 'start',
                value: 'created_at',
            },
            {
                text: 'Opis',
                align: 'start',
                value: 'description',
            },
            {
                text: 'Typ Dokumentu',
                align: 'start',
                value: 'contractType',
            },            
            { text: "", value: "actions", sortable: false },
            { text: "", value: "delActions", sortable: false },
        ],
        contractLoading:false,
        dialogContracts:false,
        deals: [],
        dealOptions: {},
        totalRecordsDeal: 0,
        descriptionContract:'',
        contractType: 'other',
        contractTypes: [
            {
                id: 'other',
                text: 'Inne'
            }, {
                id: 'auto',
                text: 'Dowód Rejestracyjny'
            },
            {
                id: 'license',
                text: 'Prawo Jazdy'
            },
            {
                id: 'medical',
                text: 'Badania Medyczne'
            },
            {
                id: 'psycho',
                text: 'Psychotesty'
            }, {
                id: 'oc',
                text: 'Ubezpieczenie'
            }],
contractFile:null,    
    }),
    watch: {
        dealOptions: {
            async handler() {
                await this.getContractsHistory()
            },
            deep: true,
        },
    },
    methods: {
        async showDialog() {
            this.dialogContracts = true
        },
        async showDeal(id) {
            try {
                const file = await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + `/contracts/get-my-document/${id}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    responseType: "blob"
                });
                let blob = new Blob([file.data], { type: file.data.type }),
                    url = window.URL.createObjectURL(blob)

                window.open(url)
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
        },        
        async createContract() {
            this.contractLoading = true
            try {
                const formData = new FormData()
                try {
                    formData.append("file", this.contractFile, this.contractFile.name);
                } catch (e) {
                    console.log('Missing File')
                }
                
                if (!this.descriptionContract.trim().length) {
                    throw ({ response: { statusText: 'Brak Opisu Dokumentu!' } })
                }
                formData.append('description', this.descriptionContract)
                formData.append('contractType', this.contractType)
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/contracts/upload-my-document",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: formData,
                });
                this.descriptionContract = ''
                this.contractType = 'other'
                this.dialogContracts = false
                await this.getContractsHistory()
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.contractLoading = false
        },  
        async showInvoiceCost(id) {
            try {
                const file = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/invoices/get-document/${id}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    responseType: "blob"
                });
                let blob = new Blob([file.data], { type: 'application/pdf' }),
                    url = window.URL.createObjectURL(blob)

                window.open(url)
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
        },
        async getContractsHistory() {
            this.contractLoading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.dealOptions
                const contracts = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/contracts/get-my-documents?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                contracts.data.data.items.forEach((item) => {
                    switch(item.contractType){
                        case 'other':
                            item.contractType = 'Inne'
                            break;
                        case 'auto':
                            item.contractType = 'Dowód Rejestracyjny'
                            break;
                        case 'license':
                            item.contractType = 'Prawo Jazdy'
                            break;
                        case 'medical':
                            item.contractType = 'Badania Medyczne'
                            break;
                        case 'psycho':
                            item.contractType = 'Psychotesty'  
                            break;
                        case 'oc':
                            item.contractType = 'Ubezpieczenie'                                                                                                                                            
                            break;
                    }
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
                })
                this.deals = contracts.data.data.items
                this.itemsPerPage = contracts.data.data.meta.itemsPerPage
                this.page = contracts.data.data.meta.currentPage
                this.totalRecordsDeal = contracts.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.contractLoading = false
        }, 
    }
}
</script>
