<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-card>        
        <v-card-title>
                Dokumenty
        <v-spacer></v-spacer>        
        </v-card-title>
                    <v-container>
                        <v-row>
                    <v-col cols="6"> <v-btn style="width: 100%;" @click="showZusForm()" class="primary">Oświadczenie ZUS</v-btn></v-col>
                    <v-col cols="6"> <v-btn style="width: 100%;" @click="showPitForm()" class="primary">Oświadczenie PIT</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showRentForm()" class="primary">Umowa Najmu</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showDealForm()" class="primary">Umowa Zlecenie</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showDeligoForm()" class="primary">Umowa Zlecenie Deligo</v-btn></v-col>
                    <v-col cols="4"><v-btn style="width: 100%;" @click="showStatementForm()" class="primary">Oświadczenie
                            Zleceniobiorcy</v-btn></v-col>
                    <v-col cols="12"><v-btn style="width: 100%;" @click="downloadDocument('rodo')" class="primary">Rodo</v-btn></v-col>
                        </v-row>
                    </v-container>
<v-dialog v-model="pitDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="pitDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Pit</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Imię Kierowcy" v-model="pitDriverFirstName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Kierowcy" v-model="pitDriverLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Email Kierowcy" v-model="pitDriverEmail"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        label="Wyrażam zgodę na przekazywanie mi przez firmę BTP Sp. z o.o. PIT-ów drogą elektroniczną na wskazany przeze mnie poniżej adres e-mailowy"
                        v-model="pitCheckbox1"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        label="Nie wyrażam zgody na przekazywanie przez firmę BTP Sp. z o.o. PIT-ów drogą elektroniczną. Proszę o wydawanie PIT-ów do rąk własnych / wysłanie listem poleconym."
                        v-model="pitCheckbox2"></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions class="text-center">
            <v-container>
                <v-btn class="primary" @click="pitDialog = false">Anuluj</v-btn>
                <v-btn class="warning" @click="downloadDocument('pit')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
                <v-btn class="success" @click="updateDocument('pit')">Zapisz</v-btn>
            </v-container>
        </v-card-actions>
    </v-card>

</v-dialog>
<v-dialog v-model="statementDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="statementDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Oświadczenie</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Kierowcy" v-model="statementDriverLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Rodowe Kierowcy"
                        v-model="statementDriverOryginalLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Pierwsze Imię Kierowcy" v-model="statementDriverFirstName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Drugie Imię Kierowcy" v-model="statementDriverSecondName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Data Urodzenia" v-model="statementDriverBirth"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Pesel" v-model="statementDriverDocId"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inny Dokument" v-model="statementDriverDocIdOther"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Gmina" v-model="statementDriverDistrict"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Województwo" v-model="statementDriverState"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Ulica" v-model="statementDriverStreet"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Numer Domu" v-model="statementDriverHome"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Numer Mieszkania" v-model="statementDriverHome2"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Kod Pocztowy" v-model="statementDriverPostalCode"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miejscowość" v-model="statementDriverCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Kraj" v-model="statementDriverCountry"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Obywatelstwo" v-model="statementDriverCitizenship"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inna Gmina" v-model="statementOtherDriverDistrict"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inne Województwo" v-model="statementOtherDriverState"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inna Ulica" v-model="statementOtherDriverStreet"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inny Numer Domu" v-model="statementOtherDriverHome"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inny Numer Mieszkania" v-model="statementOtherDriverHome2"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inny Kod Pocztowy" v-model="statementOtherDriverPostalCode"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inna Miejscowość" v-model="statementOtherDriverCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inny Kraj" v-model="statementOtherDriverCountry"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Nie jestem zatrudniony/a na podstawie umowy o pracę w innym zakładzie pracy"
                        v-model="statementCheckbox1"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Jestem zatrudniony/a na podstawie umowy o pracę w innym zakładzie pracy"
                        v-model="statementCheckbox2"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwa Zakładu Pracy" v-model="work"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Osiągam dochód wyższy niż minimalne wynagrodzenie"
                        v-model="statementCheckbox3"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Osiągam dochód niższy niż minimalne wynagrodzenie"
                        v-model="statementCheckbox4"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox
                        label="Wykonuję w innych zakładach pracy umowę zlecenia okres obowiązywania umowy zlecenia"
                        v-model="statementCheckbox5"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Od Dnia" v-model="statementDate1"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Do Dnia" v-model="statementDate2"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie wykonuje w innych zakładach pracy umowę zlecenia"
                        v-model="statementCheckbox6"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Prowadzę działalność gospodarczą" v-model="statementCheckbox7"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        label="Z tytułu prowadzenia działalności gospodarczej opłacam składki na ubezpieczenie społeczne"
                        v-model="statementCheckbox8"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        label="Z tytułu prowadzenia działalności gospodarczej nie opłacam składki na ubezpieczenie społeczne"
                        v-model="statementCheckbox9"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie prowadzę działalności gospodarczej "
                        v-model="statementCheckbox10"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Jestem Uprawniony Do" v-model="statementCheckbox11"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Emerytury" v-model="statementCheckbox12"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox label="Renty" v-model="statementCheckbox13"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Ustalonej decyzją oddziału ZUS decyzja z dnia"
                        v-model="statementZusDate1"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Znak Zus" v-model="statementZusDate2"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie jestem uprawniony do emerytury/renty"
                        v-model="statementCheckbox14"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Posiadam orzeczony stopień niezdolności do pracy"
                        v-model="statementCheckbox15"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie posiadam orzeczony stopień niezdolności do pracy"
                        v-model="statementCheckbox16"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie jestem uczniem – studentem" v-model="statementCheckbox17"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="jestem uczniem – studentem " v-model="statementCheckbox18"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nazwa Szkoły/Uczelni" v-model="university"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Nie wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym"
                        v-model="statementCheckbox19"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Wnoszę o objęcie mnie dobrowolnym ubezpieczeniem chorobowym"
                        v-model="statementCheckbox20"></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions class="text-center">
            <v-container>
                <v-btn class="primary" @click="statementDialog = false">Anuluj</v-btn>
                <v-btn class="warning" @click="downloadDocument('statement')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
                <v-btn class="success" @click="updateDocument('statement')">Zapisz</v-btn>
            </v-container>
        </v-card-actions>
    </v-card>

</v-dialog>
<v-dialog v-model="rentDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="rentDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Wypożyczenie</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Data Zawarcia Umowy" v-model="rentDate"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Imię Kierowcy" v-model="rentDriverFirstName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Kierowcy" v-model="rentDriverLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Dowód" v-model="rentDriverDoc"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Pesel" v-model="rentDriverId"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miasto" v-model="rentDriverCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Ulica" v-model="rentDriverStreet"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Marka Samochodu" v-model="rentDriverCarMake"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Model Samochodu" v-model="rentDriverCarModel"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Tablica Rej." v-model="rentDriverCarPlate"></v-text-field>
                </v-col>
                <v-col cols="8">
                    <v-text-field label="VIN" v-model="rentDriverCarVin"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Rok Prod." v-model="rentDriverCarYear"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Numer Konta" v-model="rentDriverAccNo"></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions class="text-center">
            <v-container>
                <v-btn class="primary" @click="rentDialog = false">Anuluj</v-btn>
                <v-btn class="warning" @click="downloadDocument('rent')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
                <v-btn class="success" @click="updateDocument('rent')">Zapisz</v-btn>
            </v-container>
        </v-card-actions>
    </v-card>

</v-dialog>
<v-dialog v-model="dealDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="dealDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Umowa</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Data Umowy" v-model="dealDate"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miejscowosć Umowy" v-model="dealCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Imię Kierowcy" v-model="dealFirstName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Kierowcy" v-model="dealLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miasto Kierowcy" v-model="dealDriverCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Ulica Kierowcy" v-model="dealDriverStreet"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Telefon Kierowcy" v-model="dealDriverPhone"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Dowód Kierowcy" v-model="dealDriverDoc"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Numer Konta Kierowcy" v-model="dealDriverAccountNo"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Marka Auta" v-model="dealDriverCarMake"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Model Auta" v-model="dealDriverCarModel"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Rejestracja Auta" v-model="dealDriverCarPlate"></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions class="text-center">
            <v-container>
                <v-btn class="primary" @click="dealDialog = false">Anuluj</v-btn>
                <v-btn class="warning" @click="downloadDocument('deal')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
                <v-btn class="success" @click="updateDocument('deal')">Zapisz</v-btn>
            </v-container>
        </v-card-actions>
    </v-card>

</v-dialog>    
      <v-dialog v-model="dealDeligoDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dealDeligoDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Umowa Deligo</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Data Umowy" v-model="deligoDate"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Miejscowosć Umowy" v-model="deligoCity"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Imię Kierowcy" v-model="deligoDriverFirstName"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Nazwisko Kierowcy" v-model="deligoDriverLastName"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Miasto Kierowcy" v-model="deligoDriverCity"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Ulica Kierowcy" v-model="deligoDriverStreet"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Telefon Kierowcy" v-model="deligoDriverPhone"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Dowód Kierowcy" v-model="deligoDriverId"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Numer Konta Kierowcy" v-model="deligoDriverAccNo"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Marka Auta" v-model="deligoDriverCarMake"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Model Auta" v-model="deligoDriverCarModel"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Rejestracja Auta" v-model="deligoDriverCarPlate"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="text-center">
            <v-container>
              <v-btn class="primary" @click="dealDeligoDialog = false">Anuluj</v-btn>
              <v-btn class="warning" @click="downloadDocument('deligo')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
              <v-btn class="success" @click="updateDocument('deligo')">Zapisz</v-btn>
            </v-container>
          </v-card-actions>
        </v-card>

      </v-dialog>
<v-dialog v-model="zusDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="zusDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formularz Zus</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Imię Kierowcy" v-model="zusFirstName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Drugie Imię Kierowcy (o ile w dowodzie)"
                        v-model="zusSecondName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Kierowcy" v-model="zusLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Imiona Rodziców" v-model="zusParentsName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Pesel" v-model="zusDriverId"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Seria Dowodu" v-model="zusDocId"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Data Urodzenia" v-model="zusBirthDate"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Miejsce Urodzenia" v-model="zusBirthCity"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Nazwisko Rodowe" v-model="zusOryginalLastName"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Obywatelstwo" v-model="zusDriverCitizenship"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Kasa Chorych - ZUS" v-model="zusCheckbox1"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Kasa Chorych - KRUS" v-model="zusCheckbox2"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Urząd Skarbowy" v-model="zusTaxOffice"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Kod Pocztowy" v-model="zusPostalCode"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Miejscowość" v-model="zusDriverCity"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Gmina" v-model="zusDriverDistrict"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Ulica" v-model="zusDriverStreet"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Numer Domu" v-model="zusDriverStreet1"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Numer Mieszania" v-model="zusDriverStreet2"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inny Kod Pocztowy" v-model="zusPostalOtherCode"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inna Miejscowość" v-model="zusDriverOtherCity"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inna Gmina" v-model="zusDriverOtherDistrict"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inna Ulica" v-model="zusDriverOtherStreet"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inny Numer Domu" v-model="zusDriverOtherStreet1"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Inny Numer Mieszania" v-model="zusDriverOtherStreet2"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-checkbox label="Nie ma ustalonego prawa do emerytury i renty"
                        v-model="zusCheckbox3"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox label="Ma ustalone prawo do emerytury" v-model="zusCheckbox4"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox label="Ma ustalone prawo do renty" v-model="zusCheckbox5"></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox label="Nie posiada orzeczenia o stopniu niepełnosprawności"
                        v-model="zusCheckbox6"></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox label="Ma orzeczenie o lekkim stopniu niepełnosprawności"
                        v-model="zusCheckbox7"></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox label="Ma orzeczenie o umiarkowanym stopniu niepełnosprawności"
                        v-model="zusCheckbox8"></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox label="Ma orzeczenie o znacznym stopniu niepełnosprawności"
                        v-model="zusCheckbox9"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Ubezpieczenie Chorobowe - Tak" v-model="zusCheckbox10"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox label="Ubezpieczenie Chorobowe - Nie" v-model="zusCheckbox11"></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-card-actions class="text-center">
            <v-container>
                <v-btn class="primary" @click="zusDialog = false">Anuluj</v-btn>
                <v-btn class="warning" @click="downloadDocument('zus')">Pobierz (należy zapisać zamiany przed pobraniem!)</v-btn>
                <v-btn class="success" @click="updateDocument('zus')">Zapisz</v-btn>
            </v-container>
        </v-card-actions>
    </v-card>

</v-dialog>
    </v-card>    
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
export default {
    name: "FillerView",
    data: () => {
        return {
            pitDialog: false,
            pitDriverFirstName: '',
            pitDriverLastName: '',
            pitDriverEmail: '',
            pitCheckbox1: false,
            pitCheckbox2: false,
            // 
            dealDialog: false,
            dealDate: '',
            dealCity: '',
            dealFirstName: '',
            dealLastName: '',
            dealDriverCity: '',
            dealDriverStreet: '',
            dealDriverPhone: '',
            dealDriverDoc: '',
            dealDriverAccountNo: '',
            dealDriverCarMake: '',
            dealDriverCarModel: '',
            dealDriverCarPlate: '',
            // 
            dealDeligoDialog: false,
            deligoDate: '',
            deligoCity: '',
            deligoDriverFirstName: '',
            deligoDriverLastName: '',
            deligoDriverCity: '',
            deligoDriverStreet: '',
            deligoDriverPhone: '',
            deligoDriverId: '',
            deligoDriverAccNo: '',
            deligoDriverCarMake: '',
            deligoDriverCarModel: '',
            deligoDriverCarPlate: '',            
            // 
            zusDialog: false,
            zusLastName: '',
            zusFirstName: '',
            zusSecondName: '',
            zusParentsName: '',
            zusDriverId: '',
            zusDocId: '',
            zusBirthDate: '',
            zusBirthCity: '',
            zusOryginalLastName: '',
            zusTaxOffice: '',
            zusPostalCode: '',
            zusDriverCitizenship: '',
            zusDriverCity: '',
            zusDriverDistrict: '',
            zusDriverStreet: '',
            zusDriverStreet1: '',
            zusDriverStreet2: '',
            zusPostalOtherCode: '',
            zusDriverOtherCity: '',
            zusDriverOtherDistrict: '',
            zusDriverOtherStreet: '',
            zusDriverOtherStreet1: '',
            zusDriverOtherStreet2: '',
            zusCheckbox1: false,
            zusCheckbox2: false,
            zusCheckbox3: false,
            zusCheckbox5: false,
            zusCheckbox4: false,
            zusCheckbox6: false,
            zusCheckbox7: false,
            zusCheckbox8: false,
            zusCheckbox9: false,
            zusCheckbox10: false,
            zusCheckbox11: false,
            //
            rentDialog: false,
            rentDate: '',
            rentDriverFirstName: '',
            rentDriverLastName: '',
            rentDriverDoc: '',
            rentDriverId: '',
            rentDriverCity: '',
            rentDriverStreet: '',
            rentDriverCarMake: '',
            rentDriverCarModel: '',
            rentDriverCarYear: '',
            rentDriverCarVin: '',
            rentDriverCarPlate: '',
            rentDriverAccNo: '',
            // 
            statementDialog: false,
            statementDriverFirstName: '',
            statementDriverLastName: '',
            statementDriverSecondName: '',
            statementDriverBirth: '',
            statementDriverDocId: '',
            statementDriverDocIdOther: '',
            statementDriverDistrict: '',
            statementDriverState: '',
            statementDriverStreet: '',
            statementDriverHome: '',
            statementDriverHome2: '',
            statementDriverPostalCode: '',
            statementDriverCity: '',
            statementDriverCountry: '',
            statementDriverCitizenship: '',
            statementOtherDriverDistrict: '',
            statementDriverOryginalLastName: '',
            statementOtherDriverState: '',
            statementOtherDriverStreet: '',
            statementOtherDriverHome: '',
            statementOtherDriverHome2: '',
            statementOtherDriverPostalCode: '',
            statementOtherDriverCity: '',
            statementOtherDriverCountry: '',
            statementOtherDriverCitizenship: '',
            statementDate1: '',
            statementDate2: '',
            statementZusDate1: '',
            statementZusDate2: '',
            university: '',
            work: '',
            statementCheckbox1: false,
            statementCheckbox2: false,
            statementCheckbox3: false,
            statementCheckbox4: false,
            statementCheckbox5: false,
            statementCheckbox6: false,
            statementCheckbox7: false,
            statementCheckbox8: false,
            statementCheckbox9: false,
            statementCheckbox10: false,
            statementCheckbox12: false,
            statementCheckbox11: false,
            statementCheckbox13: false,
            statementCheckbox14: false,
            statementCheckbox15: false,
            statementCheckbox16: false,
            statementCheckbox17: false,
            statementCheckbox18: false,
            statementCheckbox19: false,
            statementCheckbox20: false,             
        }
    },
    methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },          
        async updateDocument(type) {
            let data = {}
            switch (type) {
                case "pit":
                    data = {
                        
                        pitDriverFirstName: this.pitDriverFirstName,
                        pitDriverEmail: this.pitDriverEmail,
                        pitDriverLastName: this.pitDriverLastName,
                        pitCheckbox1: this.pitCheckbox1,
                        pitCheckbox2: this.pitCheckbox2,
                    }
                    break;
                case "deal":
                    data = {
                        
                        dealDate: this.dealDate,
                        dealCity: this.dealCity,
                        dealFirstName: this.dealFirstName,
                        dealLastName: this.dealLastName,
                        dealDriverCity: this.dealDriverCity,
                        dealDriverStreet: this.dealDriverStreet,
                        dealDriverPhone: this.dealDriverPhone,
                        dealDriverDoc: this.dealDriverDoc,
                        dealDriverAccountNo: this.dealDriverAccountNo,
                        dealDriverCarMake: this.dealDriverCarMake,
                        dealDriverCarModel: this.dealDriverCarModel,
                        dealDriverCarPlate: this.dealDriverCarPlate,
                    }
                    break;
                case "deligo":
                    data = {
                        driver: this.currentDriverId,
                        deligoDate: this.deligoDate,
                        deligoCity: this.deligoCity,
                        deligoDriverFirstName: this.deligoDriverFirstName,
                        deligoDriverLastName: this.deligoDriverLastName,
                        deligoDriverCity: this.deligoDriverCity,
                        deligoDriverStreet: this.deligoDriverStreet,
                        deligoDriverPhone: this.deligoDriverPhone,
                        deligoDriverId: this.deligoDriverId,
                        deligoDriverAccNo: this.deligoDriverAccNo,
                        deligoDriverCarMake: this.deligoDriverCarMake,
                        deligoDriverCarModel: this.deligoDriverCarModel,
                        deligoDriverCarPlate: this.deligoDriverCarPlate,
                    }
                    break;                    
                case "zus":
                    data = {
                        
                        zusLastName: this.zusLastName,
                        zusFirstName: this.zusFirstName,
                        zusSecondName: this.zusSecondName,
                        zusParentsName: this.zusParentsName,
                        zusDriverId: this.zusDriverId,
                        zusDocId: this.zusDocId,
                        zusBirthDate: this.zusBirthDate,
                        zusBirthCity: this.zusBirthCity,
                        zusOryginalLastName: this.zusOryginalLastName,
                        zusTaxOffice: this.zusTaxOffice,
                        zusPostalCode: this.zusPostalCode,
                        zusDriverCitizenship: this.zusDriverCitizenship,
                        zusDriverCity: this.zusDriverCity,
                        zusDriverDistrict: this.zusDriverDistrict,
                        zusDriverStreet: this.zusDriverStreet,
                        zusDriverStreet1: this.zusDriverStreet1,
                        zusDriverStreet2: this.zusDriverStreet2,
                        zusPostalOtherCode: this.zusPostalOtherCode,
                        zusDriverOtherCity: this.zusDriverOtherCity,
                        zusDriverOtherDistrict: this.zusDriverOtherDistrict,
                        zusDriverOtherStreet: this.zusDriverOtherStreet,
                        zusDriverOtherStreet1: this.zusDriverOtherStreet1,
                        zusDriverOtherStreet2: this.zusDriverOtherStreet2,
                        zusCheckbox1: this.zusCheckbox1,
                        zusCheckbox2: this.zusCheckbox2,
                        zusCheckbox3: this.zusCheckbox3,
                        zusCheckbox5: this.zusCheckbox4,
                        zusCheckbox4: this.zusCheckbox5,
                        zusCheckbox6: this.zusCheckbox6,
                        zusCheckbox7: this.zusCheckbox7,
                        zusCheckbox8: this.zusCheckbox8,
                        zusCheckbox9: this.zusCheckbox9,
                        zusCheckbox10: this.zusCheckbox10,
                        zusCheckbox11: this.zusCheckbox11,
                    }
                    break;
                case 'rent':
                    data = {
                        
                        rentDate: this.rentDate,
                        rentDriverFirstName: this.rentDriverFirstName,
                        rentDriverLastName: this.rentDriverLastName,
                        rentDriverDoc: this.rentDriverDoc,
                        rentDriverId: this.rentDriverId,
                        rentDriverCity: this.rentDriverCity,
                        rentDriverStreet: this.rentDriverStreet,
                        rentDriverCarMake: this.rentDriverCarMake,
                        rentDriverCarModel: this.rentDriverCarModel,
                        rentDriverCarYear: this.rentDriverCarYear,
                        rentDriverCarVin: this.rentDriverCarVin,
                        rentDriverCarPlate: this.rentDriverCarPlate,
                        rentDriverAccNo: this.rentDriverAccNo,
                    }
                    break;
                case 'statement':
                    data = {
                        
                        statementDriverFirstName: this.statementDriverFirstName,
                        statementDriverOryginalLastName: this.statementDriverOryginalLastName,
                        statementDriverLastName: this.statementDriverLastName,
                        statementDriverSecondName: this.statementDriverSecondName,
                        statementDriverBirth: this.statementDriverBirth,
                        statementDriverDocId: this.statementDriverDocId,
                        statementDriverDocIdOther: this.statementDriverDocIdOther,
                        statementDriverDistrict: this.statementDriverDistrict,
                        statementDriverState: this.statementDriverState,
                        statementDriverStreet: this.statementDriverStreet,
                        statementDriverHome: this.statementDriverHome,
                        statementDriverHome2: this.statementDriverHome2,
                        statementDriverPostalCode: this.statementDriverPostalCode,
                        statementDriverCity: this.statementDriverCity,
                        statementDriverCountry: this.statementDriverCountry,
                        statementDriverCitizenship: this.statementDriverCitizenship,
                        statementOtherDriverDistrict: this.statementOtherDriverDistrict,
                        statementOtherDriverState: this.statementOtherDriverState,
                        statementOtherDriverStreet: this.statementOtherDriverStreet,
                        statementOtherDriverHome: this.statementOtherDriverHome,
                        statementOtherDriverHome2: this.statementOtherDriverHome2,
                        statementOtherDriverPostalCode: this.statementOtherDriverPostalCode,
                        statementOtherDriverCity: this.statementOtherDriverCity,
                        statementOtherDriverCountry: this.statementOtherDriverCountry,
                        statementOtherDriverCitizenship: this.statementOtherDriverCitizenship,
                        statementDate1: this.statementDate1,
                        statementDate2: this.statementDate2,
                        statementZusDate1: this.statementZusDate1,
                        statementZusDate2: this.statementZusDate2,
                        university: this.university,
                        work: this.work,
                        statementCheckbox1: this.statementCheckbox1,
                        statementCheckbox2: this.statementCheckbox2,
                        statementCheckbox3: this.statementCheckbox3,
                        statementCheckbox4: this.statementCheckbox4,
                        statementCheckbox5: this.statementCheckbox5,
                        statementCheckbox6: this.statementCheckbox6,
                        statementCheckbox7: this.statementCheckbox7,
                        statementCheckbox8: this.statementCheckbox8,
                        statementCheckbox9: this.statementCheckbox9,
                        statementCheckbox10: this.statementCheckbox10,
                        statementCheckbox12: this.statementCheckbox11,
                        statementCheckbox11: this.statementCheckbox12,
                        statementCheckbox13: this.statementCheckbox13,
                        statementCheckbox14: this.statementCheckbox14,
                        statementCheckbox15: this.statementCheckbox15,
                        statementCheckbox16: this.statementCheckbox16,
                        statementCheckbox17: this.statementCheckbox17,
                        statementCheckbox18: this.statementCheckbox18,
                        statementCheckbox19: this.statementCheckbox19,
                        statementCheckbox20: this.statementCheckbox20,
                    }
                    break;
            }
            await this.toggleLoading(true)
            try {
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + `/documents/update-my-document/${type}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data
                });
                Swal.fire("Sukces", "Zapisano", 'success')
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false)
        },
        async downloadDocument(type) {
            try {
                const file = await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + `/documents/download-my-document/${type}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    responseType: 'blob'
                });
                let blob = new Blob([file.data], { type: file.data.type }),
                    url = window.URL.createObjectURL(blob)
                window.open(url)
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", "Nie Można Pobrać Pliku, czy uzupełniłeś wszystkie pola oraz zapisałeś zmiany przed pobraniem?", "warning")
            }
        },
        async showPitForm() {
            this.pitDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/pit`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.pitDriverFirstName = details.data.data.pitDriverFirstName
            this.pitDriverEmail = details.data.data.pitDriverEmail
            this.pitDriverLastName = details.data.data.pitDriverLastName
            this.pitCheckbox1 = details.data.data.pitCheckbox1
            this.pitCheckbox2 = details.data.data.pitCheckbox2
        },
        async showDeligoForm() {
            this.dealDeligoDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/deligo`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.deligoDate = details.data.data.deligoDate
            this.deligoCity = details.data.data.deligoCity
            this.deligoDriverFirstName = details.data.data.deligoDriverFirstName
            this.deligoDriverLastName = details.data.data.deligoDriverLastName
            this.deligoDriverCity = details.data.data.deligoDriverCity
            this.deligoDriverStreet = details.data.data.deligoDriverStreet
            this.deligoDriverPhone = details.data.data.deligoDriverPhone
            this.deligoDriverId = details.data.data.deligoDriverId
            this.deligoDriverAccNo = details.data.data.deligoDriverAccNo
            this.deligoDriverCarMake = details.data.data.deligoDriverCarMake
            this.deligoDriverCarModel = details.data.data.deligoDriverCarModel
            this.deligoDriverCarPlate = details.data.data.deligoDriverCarPlate
        },         
        async showZusForm() {
            this.zusDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/zus`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.zusLastName = details.data.data.zusLastName
            this.zusFirstName = details.data.data.zusFirstName
            this.zusSecondName = details.data.data.zusSecondName
            this.zusParentsName = details.data.data.zusParentsName
            this.zusDriverId = details.data.data.zusDriverId
            this.zusDocId = details.data.data.zusDocId
            this.zusBirthDate = details.data.data.zusBirthDate
            this.zusBirthCity = details.data.data.zusBirthCity
            this.zusOryginalLastName = details.data.data.zusOryginalLastName
            this.zusTaxOffice = details.data.data.zusTaxOffice
            this.zusPostalCode = details.data.data.zusPostalCode
            this.zusDriverCitizenship = details.data.data.zusDriverCitizenship
            this.zusDriverCity = details.data.data.zusDriverCity
            this.zusDriverDistrict = details.data.data.zusDriverDistrict
            this.zusDriverStreet = details.data.data.zusDriverStreet
            this.zusDriverStreet1 = details.data.data.zusDriverStreet1
            this.zusDriverStreet2 = details.data.data.zusDriverStreet2
            this.zusPostalOtherCode = details.data.data.zusPostalOtherCode
            this.zusDriverOtherCity = details.data.data.zusDriverOtherCity
            this.zusDriverOtherDistrict = details.data.data.zusDriverOtherDistrict
            this.zusDriverOtherStreet = details.data.data.zusDriverOtherStreet
            this.zusDriverOtherStreet1 = details.data.data.zusDriverOtherStreet1
            this.zusDriverOtherStreet2 = details.data.data.zusDriverOtherStreet2
            this.zusCheckbox1 = details.data.data.zusCheckbox1
            this.zusCheckbox2 = details.data.data.zusCheckbox2
            this.zusCheckbox3 = details.data.data.zusCheckbox3
            this.zusCheckbox5 = details.data.data.zusCheckbox4
            this.zusCheckbox4 = details.data.data.zusCheckbox5
            this.zusCheckbox6 = details.data.data.zusCheckbox6
            this.zusCheckbox7 = details.data.data.zusCheckbox7
            this.zusCheckbox8 = details.data.data.zusCheckbox8
            this.zusCheckbox9 = details.data.data.zusCheckbox9
            this.zusCheckbox10 = details.data.data.zusCheckbox10
            this.zusCheckbox11 = details.data.data.zusCheckbox11
        },
        async showRentForm() {
            this.rentDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/rent`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.rentDate = details.data.data.rentDate
            this.rentDriverFirstName = details.data.data.rentDriverFirstName
            this.rentDriverLastName = details.data.data.rentDriverLastName
            this.rentDriverDoc = details.data.data.rentDriverDoc
            this.rentDriverId = details.data.data.rentDriverId
            this.rentDriverCity = details.data.data.rentDriverCity
            this.rentDriverStreet = details.data.data.rentDriverStreet
            this.rentDriverCarMake = details.data.data.rentDriverCarMake
            this.rentDriverCarModel = details.data.data.rentDriverCarModel
            this.rentDriverCarYear = details.data.data.rentDriverCarYear
            this.rentDriverCarVin = details.data.data.rentDriverCarVin
            this.rentDriverCarPlate = details.data.data.rentDriverCarPlate
            this.rentDriverAccNo = details.data.data.rentDriverAccNo
        },
        async showStatementForm() {
            this.statementDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/statement`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.statementDriverFirstName = details.data.data.statementDriverFirstName
            this.statementDriverLastName = details.data.data.statementDriverLastName
            this.statementDriverOryginalLastName = details.data.data.statementDriverOryginalLastName
            this.statementDriverSecondName = details.data.data.statementDriverSecondName
            this.statementDriverBirth = details.data.data.statementDriverBirth
            this.statementDriverDocId = details.data.data.statementDriverDocId
            this.statementDriverDocIdOther = details.data.data.statementDriverDocIdOther
            this.statementDriverDistrict = details.data.data.statementDriverDistrict
            this.statementDriverState = details.data.data.statementDriverState
            this.statementDriverStreet = details.data.data.statementDriverStreet
            this.statementDriverHome = details.data.data.statementDriverHome
            this.statementDriverHome2 = details.data.data.statementDriverHome2
            this.statementDriverPostalCode = details.data.data.statementDriverPostalCode
            this.statementDriverCity = details.data.data.statementDriverCity
            this.statementDriverCountry = details.data.data.statementDriverCountry
            this.statementDriverCitizenship = details.data.data.statementDriverCitizenship
            this.statementOtherDriverDistrict = details.data.data.statementOtherDriverDistrict
            this.statementOtherDriverState = details.data.data.statementOtherDriverState
            this.statementOtherDriverStreet = details.data.data.statementOtherDriverStreet
            this.statementOtherDriverHome = details.data.data.statementOtherDriverHome
            this.statementOtherDriverHome2 = details.data.data.statementOtherDriverHome2
            this.statementOtherDriverPostalCode = details.data.data.statementOtherDriverPostalCode
            this.statementOtherDriverCity = details.data.data.statementOtherDriverCity
            this.statementOtherDriverCountry = details.data.data.statementOtherDriverCountry
            this.statementOtherDriverCitizenship = details.data.data.statementOtherDriverCitizenship
            this.statementDate1 = details.data.data.statementDate1
            this.statementDate2 = details.data.data.statementDate2
            this.statementZusDate1 = details.data.data.statementZusDate1
            this.statementZusDate2 = details.data.data.statementZusDate2
            this.university = details.data.data.university
            this.work = details.data.data.work
            this.statementCheckbox1 = details.data.data.statementCheckbox1
            this.statementCheckbox2 = details.data.data.statementCheckbox2
            this.statementCheckbox3 = details.data.data.statementCheckbox3
            this.statementCheckbox4 = details.data.data.statementCheckbox4
            this.statementCheckbox5 = details.data.data.statementCheckbox5
            this.statementCheckbox6 = details.data.data.statementCheckbox6
            this.statementCheckbox7 = details.data.data.statementCheckbox7
            this.statementCheckbox8 = details.data.data.statementCheckbox8
            this.statementCheckbox9 = details.data.data.statementCheckbox9
            this.statementCheckbox10 = details.data.data.statementCheckbox10
            this.statementCheckbox12 = details.data.data.statementCheckbox11
            this.statementCheckbox11 = details.data.data.statementCheckbox12
            this.statementCheckbox13 = details.data.data.statementCheckbox13
            this.statementCheckbox14 = details.data.data.statementCheckbox14
            this.statementCheckbox15 = details.data.data.statementCheckbox15
            this.statementCheckbox16 = details.data.data.statementCheckbox16
            this.statementCheckbox17 = details.data.data.statementCheckbox17
            this.statementCheckbox18 = details.data.data.statementCheckbox18
            this.statementCheckbox19 = details.data.data.statementCheckbox19
            this.statementCheckbox20 = details.data.data.statementCheckbox20
        },
        async showDealForm() {
            this.dealDialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/documents/get-document-for-me/deal`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.dealDate = details.data.data.dealDate
            this.dealCity = details.data.data.dealCity
            this.dealFirstName = details.data.data.dealFirstName
            this.dealLastName = details.data.data.dealLastName
            this.dealDriverCity = details.data.data.dealDriverCity
            this.dealDriverStreet = details.data.data.dealDriverStreet
            this.dealDriverPhone = details.data.data.dealDriverPhone
            this.dealDriverDoc = details.data.data.dealDriverDoc
            this.dealDriverAccountNo = details.data.data.dealDriverAccountNo
            this.dealDriverCarMake = details.data.data.dealDriverCarMake
            this.dealDriverCarModel = details.data.data.dealDriverCarModel
            this.dealDriverCarPlate = details.data.data.dealDriverCarPlate
        },
    }
}
</script>

