<template>
    <v-card>
        <v-toolbar>
          <v-toolbar-title>Składowe Rozliczenia Za Tydzień:</v-toolbar-title>
          <v-spacer></v-spacer>
           
        </v-toolbar>
        <v-container>
        <v-text-field type="week" v-model="weekNo" label="Wybierz Tydzień" @change="loadDashboard()"></v-text-field>
        <v-btn color="primary" text-center @click="loadDashboard()">Odśwież</v-btn>
        </v-container>
      <v-card-text>    
        <v-card>
            <h3>Wypłaty są rozliczane dla kwot powyżej 231 zł netto</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Pozycja
                </th>
                <th class="text-left">
                  Kwota
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in settlementDetailsTable"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          </v-card>
          </v-card-text>
          </v-card>
</template>

<script>
import * as dayjs from 'dayjs'
import axios from 'axios'
export default {
    name: "DashboardView",
    data: () => ({   
        weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week() - 1),
        loading: false, 
        settlementDetailsTable: [],                      
    }),
    async mounted() {
        await this.loadDashboard()
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },              
        async loadDashboard() {
            await this.toggleLoading(true)
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-week/${this.weekNo}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });      
            if(details.data.data){
                this.settlementDetailsTable = []
                this.settlementDetailsTable.push({
                    'name': 'Rozliczenie za:',
                    'price': (details.data.data.weekNo + '/' + details.data.data.yearNo)
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Bolt',
                    'price': details.data.data.boltSettlement || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Uber',
                    'price': details.data.data.uberSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie FreeNow',
                    'price': details.data.data.freeNowSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie UberEats',
                    'price': details.data.data.uberEatsSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Deligo',
                    'price': details.data.data.deligoSettlement || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Bonusy',
                    'price': details.data.data.bonusSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie DKV',
                    'price': details.data.data.dkvSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Wynajem Auta',
                    'price': (details.data.data.rentPrice / 100).toFixed(2).replace('.', ',') || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Zwrot (faktury)',
                    'price': details.data.data.invoicesSettlements || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'ZUS',
                    'price': (details.data.data.zusSettlements / 100).toFixed(2).replace('.', ',')
                })
                this.settlementDetailsTable.push({
                    'name': 'Koszt Rozliczenia',
                    'price': (details.data.data.settlementCost / 100).toFixed(2).replace('.', ',')
                })
                this.settlementDetailsTable.push({
                    'name': 'Koszt Administracyjno-Księgowy',
                    'price': (details.data.data.administrationTax / 100).toFixed(2).replace('.', ',')
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Gotówka',
                    'price': (details.data.data.totalCash / 100).toFixed(2).replace('.', ',') || '0,00'
                })
                this.settlementDetailsTable.push({
                    'name': 'Przelew Zlecenie',
                    'price': (details.data.data.totalZus / 100).toFixed(2).replace('.', ',')
                })
                this.settlementDetailsTable.push({
                    'name': 'Przelew Wynajem',
                    'price': (details.data.data.totalRent / 100).toFixed(2).replace('.', ',')
                })
                this.settlementDetailsTable.push({
                    'name': 'Łącznie Przelew',
                    'price': ((details.data.data.totalTotal + details.data.data.totalZus + details.data.data.totalRent) / 100).toFixed(2).replace('.', ',')
                })
            }      
            await this.toggleLoading(false)            
        }
    }
}
</script>
