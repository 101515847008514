import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Invoices from '../views/Invoices.vue'
import Account from '../views/Config.vue'
import Settlements from '../views/Settlements.vue'
import Costs from '../views/Costs.vue'
import Form from '../views/Form.vue'
import Documents from '../views/Documents.vue'
import Filler from '../views/Filler.vue'
import ImportPreview from '../views/ImportPreview.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  }, 
  {
    path: '/import-preview',
    name: 'ImportPreview',
    component: ImportPreview
  },    
  {
    path: '/settlements',
    name: 'Settlements',
    component: Settlements
  }, 
    {
    path: '/filler',
    name: 'Filler',
    component: Filler
  }, 
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  }, 
  {
    path: '/account',
    name: 'Account',
    component: Account
  }, 
  {
    path: '/costs',
    name: 'Costs',
    component: Costs
  },  
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },                
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  }      
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {  
  if ((to.name !== 'Login' || to.name !=='Form') && (localStorage.getItem('user')!='true')) {    
    if(to.name=='Form'){
      next()
    }
    else if(to.name=='Login'){
      next()
    }else{
      next({ name: 'Login' })
    }     
  }else {    
    next()
  }
})
export default router
