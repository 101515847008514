<!-- eslint-disable vue/valid-v-slot -->
<template>    
    <v-card>
        <v-card-title>
            Rozliczenia
            <v-spacer></v-spacer>
        </v-card-title>
        <h3>Minimalna kwota do wypłaty: 231 netto</h3>
        <v-data-table :headers="headers" :items="settlements" :options.sync="options" :server-items-length="totalRecords"
            :loading="loading" class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <v-btn class="primary" @click="getSettlementDetails(item.id)">
                    <v-icon small class="pt-1">mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-footer class="justify-center">
        </v-footer>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-card-title>
                <span class="text-h5">Szczegóły Rozliczenia</span>
            </v-card-title>
            <v-card-text>
        <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Pozycja
                </th>
                <th class="text-left">
                  Kwota
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in settlementDetailsTable"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    Anuluj
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-card>    
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

export default {
    name: "SettlementsView",
    data: () => ({
        dkvCost:0,
        totalCash:0,      
        search: '',
        options: {},
        totalRecords: 0,
        loading: false,
        dialog:false,
        settlementDetailsTable: [],
        headers: [
            { text: 'Data Wygenerowania Wypłaty', value: 'created_at' },
            {
                text: 'Tydzień',
                align: 'start',
                value: 'weekNo',
                sortable: false,
            },  
            {
                text: 'Rok',
                align: 'start',
                value: 'yearNo',
                sortable: false,
            },                         
            {
                text: 'Suma Do Przelewu',
                align: 'start',
                value: 'total',
                sortable: false,
            },           
            { text: '', value: 'actions', sortable: false, },
        ],
        detailedBolt: [],
        detailedUber: [],
        detailedFreeNow: [],
        headersBolt: [
            {
                text: 'Bolt Brutto',
                align: 'start',
                value: 'bolt_total',
                sortable: false,
            },
            {
                text: 'Bolt Gotówka',
                align: 'start',
                value: 'bolt_cashFee',
                sortable: false,
            },
            {
                text: 'Opłata Bolt',
                align: 'start',
                value: 'bolt_boltFee',
                sortable: false,
            },
            {
                text: 'Odwołania Bolt',
                align: 'start',
                value: 'bolt_cancelFee',
                sortable: false,
            },
            {
                text: 'Bonus Bolt',
                align: 'start',
                value: 'bolt_bonusFee',
                sortable: false,
            },
            {
                text: 'Zwroty Bolt',
                align: 'start',
                value: 'bolt_returnsFee',
                sortable: false,
            },
            {
                text: 'Napiwki Bolt',
                align: 'start',
                value: 'bolt_tipFee',
                sortable: false,
            },
        ],
        headersUber: [
            {
                text: 'Uber Brutto',
                align: 'start',
                value: 'uber_totalWithCash',
                sortable: false,
            },
            {
                text: 'Uber Gotówka',
                align: 'start',
                value: 'uber_cashFee',
                sortable: false,
            },
            {
                text: 'Opłata za Oczekiwanie',
                align: 'start',
                value: 'uber_waitingFee',
                sortable: false,
            },
            {
                text: 'Napiwki Uber',
                align: 'start',
                value: 'uber_tipFee',
                sortable: false,
            },
            {
                text: 'Odwołania Uber',
                align: 'start',
                value: 'uber_cancelFee',
                sortable: false,
            },
            {
                text: 'Uber Quest',
                align: 'start',
                value: 'uber_quest',
                sortable: false,
            },
            {
                text: 'Polecenia',
                align: 'start',
                value: 'uber_recomendationFee',
                sortable: false,
            },
        ],
        headersFree: [
            {
                text: 'FreeNow Brutto',
                align: 'start',
                value: 'freenow_tourTotal',
                sortable: false,
            },
            {
                text: 'FreeNow Gotówka',
                align: 'start',
                value: 'freenow_tourTotalCash',
                sortable: false,
            },
            {
                text: 'Napiwki',
                align: 'start',
                value: 'freenow_tip',
                sortable: false,
            },
            {
                text: 'Bonus',
                align: 'start',
                value: 'freenow_bonusBrutto',
                sortable: false,
            },
        ],         
        settlements: [],  
        boltEarn: 0,
        uberTaxiEarn: 0,
        uberEatsEarn: 0,
        freeNowEarn: 0,
        settlementCost: 0,
        zusCost: 0,
        wireTotal: 0              
    }),
    watch: {
        options: {
            async handler() {
                await this.loadPayments()
            },
            deep: true,
        },
        async search() {
            await this.loadPayments()
        }
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async getSettlementDetails(id){
            await this.toggleLoading(true)
            this.dialog = true
            const details = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-id/${id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });  
            this.settlementDetailsTable = []
            this.settlementDetailsTable.push({
                'name': 'Rozliczenie za:',
                'price': (details.data.data.weekNo + '/' + details.data.data.yearNo)
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Bolt',
                'price': details.data.data.boltSettlement || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Uber',
                'price': details.data.data.uberSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie FreeNow',
                'price': details.data.data.freeNowSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie UberEats',
                'price': details.data.data.uberEatsSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Deligo',
                'price': details.data.data.deligoSettlement || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Bonusy',
                'price': details.data.data.bonusSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie DKV',
                'price': details.data.data.dkvSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Wynajem Auta',
                'price': (details.data.data.rentPrice / 100).toFixed(2).replace('.', ',') || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Zwrot (faktury)',
                'price': details.data.data.invoicesSettlements || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'ZUS',
                'price': (details.data.data.zusSettlements / 100).toFixed(2).replace('.', ',')
            })
            this.settlementDetailsTable.push({
                'name': 'Koszt Rozliczenia',
                'price': (details.data.data.settlementCost / 100).toFixed(2).replace('.', ',')
            })
            this.settlementDetailsTable.push({
                'name': 'Koszt Administracyjno-Księgowy',
                'price': (details.data.data.administrationTax / 100).toFixed(2).replace('.', ',')
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Gotówka',
                'price': (details.data.data.totalCash / 100).toFixed(2).replace('.', ',') || '0,00'
            })
            this.settlementDetailsTable.push({
                'name': 'Przelew Zlecenie',
                'price': (details.data.data.totalZus / 100).toFixed(2).replace('.', ',')
            })
            this.settlementDetailsTable.push({
                'name': 'Przelew Wynajem',
                'price': (details.data.data.totalRent / 100).toFixed(2).replace('.', ',')
            })
            this.settlementDetailsTable.push({
                'name': 'Łącznie Przelew',
                'price': ((details.data.data.totalTotal + details.data.data.totalZus + details.data.data.totalRent) / 100).toFixed(2).replace('.', ',')
            })           
            await this.toggleLoading(false)                     
        },
        async loadPayments() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const imported = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/settlements/get-my-payments?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                imported.data.data.items.forEach((item)=>{
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
                    item.total = ((item.totalZus + item.totalRent + item.totalReturn) / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });
                })
                this.settlements = imported.data.data.items
                this.itemsPerPage = imported.data.data.meta.itemsPerPage
                this.page = imported.data.data.meta.currentPage
                this.totalRecords = imported.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
    },
}
</script>
